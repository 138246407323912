import React from 'react'
import './Header.css'

class Header extends React.Component {

  constructor(props){
    super(props)
    this.state = {expanded: false, width: window.innerWidth}
  }

  componentDidMount(){
    window.setTimeout(()=>this.goToSplash(),1)
    window.addEventListener("resize", this.resizeWindow.bind(this))
  }

  resizeWindow(){
    this.setState({width: window.innerWidth})
  }
  
  goToSplash = () => {
    if (this.state.width > 800) {
      window.scrollTo({left: 0,top:0,behavior: 'smooth'})
    } else window.scrollTo({left: 0, top: 0, behavior:'smooth'})
  }
  goToWho = () => {
    if (this.state.width > 800) {
      window.scrollTo({left: window.innerWidth,top:0,behavior: 'smooth'})
    } else window.scrollTo({left: 0, top: window.innerHeight, behavior:'smooth'})
  }
  goToWhat = () => {
    let whoHeight = document.getElementsByClassName('Who').item(0).offsetHeight
    if (this.state.width > 800) {
      window.scrollTo({left: 0,top: window.innerHeight, behavior: 'smooth'})
    } else window.scrollTo({left: 0, top: window.innerHeight + whoHeight, behavior:'smooth'})
  }
  goToMore = () => {
    let whoHeight = document.getElementsByClassName('Who').item(0).offsetHeight
    if (this.state.width > 800) {
      window.scrollTo({left: window.innerWidth, top:window.innerHeight,behavior: 'smooth'})
    } else window.scrollTo({left: 0, top: window.innerHeight * 2 + whoHeight, behavior:'smooth'})
  }

  render(){
    return this.state.width > 800 ?
    (
      <div className='Header'>
        <img src='/logo.png' onClick={this.goToSplash} alt='home'/>
        <button onClick={this.goToWho}>who we are</button>
        <button onClick={this.goToWhat}>what we do</button>
        <button onClick={this.goToMore}>learn more</button>
      </div> 
    )
    :
    (
      <div className='Header'>
      <img src='/logo.png' onClick={this.goToSplash} alt='home'/>
      <img src='/menu.png' id='Menu' alt='Menu' onClick={()=>this.setState({expanded:!this.state.expanded})}/>
      {this.state.expanded && (
        <div onClick={()=>this.setState({expanded:false})}>
          <div>
            <button onClick={this.goToWho}>who we are</button>
            <button onClick={this.goToWhat}>what we do</button>
            <button onClick={this.goToMore}>learn more</button>
          </div>
        </div>
      )}
      </div> 
    )
  }
}

export default Header
