import React from 'react'
import './What.css'

function What() {

  const topic = (name, photo, caption)=>{
    return(
      <div>
        <img src={photo} alt={name}/>
        <h3>{name}</h3>
        <h6>{caption}</h6>
      </div>
    )
  }

  return (
    <div className='What'>
      <h1>What We Do</h1>
      <p>
        We build and incubate our products in-house, and partner with others when we believe our technology can make the difference.
        We assume that 90% of what we hear are buzzwords and BS, and we strive to understand the remaining 10% and how it can make a real impact - for ourselves,
        for our partners, and for everyone.
      </p>
      { topic('Artificial Intelligence','/ai_ph.png',"We've built a custom training and inference platform using Tensorflow and a lot of GPUs for computer vision, object recognition and other unique use cases.") }
      { topic('Blockchain','/network.png',"We're building a busines cooperative to serve stakeholders instead of shareholders. Stealth.") }
      { topic('Social Impact','/heart.png','Partner of BallotReady, a non-partisan venture to educate voters all the way down ballot and turn out the vote.') }
    </div> 
  )
}

export default What
