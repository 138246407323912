import React from 'react';
import Who from './Who/Who'
import Splash from './Splash/Splash'
import What from './What/What'
import More from './More/More'
import Header from './Header/Header'
import './App.css';

function App() {
  return (
    <div className="App">
      <Header/>
      <Splash/>
      <Who/>
      <What/>
      <More/>
    </div>
  );
}

export default App;
