import React from 'react'
import './Who.css'

function Who(props) {

  const person = (name, photo, position, caption, linkedin)=>{
    return(
      <div>
        <a href={linkedin} target='_blank' rel='noopener noreferrer'>
          <img src={photo} alt={name}/>
        </a>
        <h3>{name}</h3>
        <h6>{position}</h6>
        <p>{caption}</p>
      </div>
    )
  }

  return (
    <div className='Who'>
      <h1>Who We Are</h1>
      <p>
        We are a tech studio of engineers, entrepreneurs, marketers and investors with experience from companies including Grubhub and Facebook.
        Our team is lean, efficient, and prioritizes using frontier technologies for good.
      </p>
      { person('Mike Saunders','/mike.jpg','Business','Slowly but surely uncovering the secrets of the internet.','https://www.linkedin.com/in/saunders/') }
      { person('Tom Hayden','/tom.jpeg','Technology','Striving for the inexistent LinkedIn 40 under 40.','https://www.linkedin.com/in/haydenth/') }
      { person('Rich Reising','/rich.png','Operations','Always doing well by always doing good.','https://www.linkedin.com/in/richreising/') }
      { person('Daniela Sterba','/daniela.jpeg','Marketing','Well, basically software engineering these days...','https://www.linkedin.com/in/danielasterba/') }
      { person('Brad Johnson','/brad.jpg','Sales','Curating big data for small business.','https://www.linkedin.com/in/bradley-johnson-86870128/') }
      { person('Ryan McHenry','/ryan.jpg','Product','Most efficient as a 10-6 kind of guy.','https://www.linkedin.com/in/ryanmchenry2/') }
      { person('Scott Wilson','/scott.png','Engineering','May or may not actually exist.','https://www.linkedin.com/in/scott-wilson-00550a47/') }
    </div> 
  )
}

export default Who
