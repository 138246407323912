import React from 'react'
import './Splash.css'

function Splash(props) {
  return (
    <div className='Splash'>
      <img src='/logo.png' alt='logo'/>
      <h1>Blueprint:</h1>
      <p>Simplifying the Impossible.</p>
    </div> 
  )
}

export default Splash
