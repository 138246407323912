import React from 'react'
import './More.css'

function More() {
  return (
   <div className='More'>
      <h1>Get in Touch</h1>
      <p>
        Interested in working with us? Reach out and let us know. We're always looking for people that are the best at what they do.
      </p>
      <form action='//formspree.io/mike@blpt.co' method="POST">
        <input type="hidden" name="_cc" value="ryan@blpt.co" />
        <label htmlFor="name">
          <input type="text" placeholder="Your name..." name="name"/>
        </label>
        <br/>
        <label htmlFor="_replyto">
          <input type="email" placeholder="Your email..." name="_replyto"/>
        </label>
        <br/>
        <label htmlFor="message">
          <textarea rows="3" placeholder="Your message..." name="message" />
        </label>
        <br/>
        <button type="submit">Submit</button>
      </form>
   </div> 
  )
}

export default More
